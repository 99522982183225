/**
    NICO - 13/02/2024 - En caso de necesitar extender las clases de los iconos de soul-icons, se debe hacer en este archivo.

    Esto debe realizar SOLAMENTE EN CASOS EXCEPCIONALES, porque lo ideal es que los iconos siempre se utilicen así: <i class="sicon-xxx-yyy"></i>.
    Por ejemplo, ahora mismo se utiliza para el Ag-Grid porque no podemos modificar el HTML que genera el grid, ni podemos modificar sus clases.

    Importante: alguien podría pensar que es mejor hacerlo en el propio component.scss, pero no es así.
    El motivo es que si por ejemplo queremos poner el icono del bolígrafo y vemos que es el '\aaa', pondremos content: '\aaa' en el componente.
    Eso funcionará, pero si nosotros después actualizamos las SoulFoundations y el contenido del icono cambia, y pasa a ser por ejemplo '\bbb', el componente no se actualizará.
    Por lo tanto el componente seguirá apuntando al content '\aaa', que ahora puede ser cualquier otro icono que nada tenga que ver.
    Poniendo los @extend aquí, si cambia el contenido del icono, se actualizará en todos los componentes que lo utilicen.
*/
@import "node_modules/@endalia/soul-foundations/dist/icons-font/soulicons.scss";

.e-header-cell-editable {
    @extend .sicon-edit-solid;

    &::before {
        font-family: 'soulicons' !important;
        font-style: normal;
        font-weight: normal !important;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        margin-right: 0.5rem;
        color: rgba(#818a91, 0.5);
    }
}

.e-cell-editable:hover {
    border-radius: 50px;
    background-color: rgba(#cccccc, 0.35);
    border: 1px solid #fafafa;
    padding-right: 25px !important;

    @extend .sicon-edit-solid;

    &::before {
        font-family: 'soulicons' !important;
        font-style: normal;
        font-weight: normal !important;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        position: absolute;
        right: 8px;
        top: 6px;
        bottom: 0;
        color: rgba(#3b79ce, 1);
    }
}